<template>
  <vx-card class="edit-company-page main-box top-zero-radius md:w-1/2" no-shadow>
    <vs-row
      vs-type="flex"
      vs-justify="space-around">

      <!-- Img Row -->
      <div class="flex flex-wrap items-center mb-base justify-center">
        <custom-profile-image-input accept-type="image/png" :default-image="require('@/assets/icons/TENNIS_COURT.svg')" v-model="clubInfo.avatar" />

        <vs-row
          vs-type="flex"
          vs-justify="center">
          <p class="w-4/5 text-xs text-center mt-2">{{ $t('setting.Company.validators.logoRule') }}</p>
        </vs-row>
      </div>
      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full md:w-1/3 d-inline-flex">
        <p class="h5 text-primary">{{ $t('setting.Company.description.generalInfo') }}</p>
        <span class="text-justify text-xs">{{ $t('setting.Company.labels.generalInfo') }}</span>
      </vs-col>

      <vs-col class="w-full md:w-2/3 d-inline-flex">
        <custom-validate-input :classes="{'w-full': true, 'mb-base': true}"
                               align="right"
                               rtl
                               :label-placeholder="$t('setting.General.club.name')"
                                v-model="clubInfo.name"/>
      </vs-col>

      <vs-divider class="my-5"></vs-divider>
    </vs-row>

    <vs-row>

      <vs-col class="w-full">

        <!-- Save & Reset Button -->
        <div class="flex flex-wrap items-center justify-end">
          <vs-button id="saveBTN" @click="sendForm" class="useral-action-button ml-auto mt-2" color="success">
            {{ $t('setting.buttons.save') }}
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import datePicker from 'vue-persian-datetime-picker'
import {editClubSetting, getClubSetting, uploadAvatar} from '@/http/requests/settings/setting'
import {getProvinces} from '@/http/requests/provinces'
import CustomValidateInput from "../../../../components/customInput/customValidateInput";
import CustomPhoneNumberInput from "../../../../components/customInput/customPhoneNumberInput";
import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
import CustomSelect from "../../../../components/customSelect/customSelect";
import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput";

export default {
  name: 'editCompany',
  components: {
    CustomProfileImageInput,
    CustomSelect,
    CustomValidateTextarea,
    CustomPhoneNumberInput,
    CustomValidateInput,
    datePicker
  },
  metaInfo () {
    return {
      title: this.$t('setting.Company.edit.title')
    }
  },
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      addressRegex: this.$validator('regex.company.address'),
      provinces: [],
      cities: {},
      clubInfo: {
        code: '',
        name: {
          value: '',
          isValid: true
        },
        avatar: {
          value: '',
          isValid: true
        },
        telSupport: {
          value: '',
          isValid: true
        },
        whatsAppSupport: {
          value: '',
          isValid: true
        },
        province: {
          id: '',
          label: ''
        },
        city: {
          id: '',
          label: ''
        },
        address: {
          value: '',
          isValid: true
        }
      },
      activeLogoPrompt: false,
      tabActions: [
        {
          toolbar: [
            {
              id: 'saveBTN',
              // i18n: 'navbar.save',
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'Settings', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      club: 'setting/getClubInfo'
    }),
    activeUserInfo () {
      return this.$store.state.auth.user
    }
  },
  created () {
    // this.getProvinces()
    this.getSetting()
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.tabActions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto md:w-1/2')
    })
  },
  mounted () {
    if (this.club.club_code) this.clubInfo.code = this.club.club_code

    if (this.club.club_name) this.clubInfo.name.value = this.club.club_name

    if (this.club.club_logo) this.clubInfo.logo = this.club.club_logo

    if (this.club.address) this.clubInfo.address = this.club.address

    if (this.club.phoneNumber) this.clubInfo.phoneNumber = this.club.phoneNumber
  },
  methods: {
    getSetting () {
      getClubSetting().then((response) => {
        const club = response.data.data
        this.clubInfo.name.value = club.club_name
        this.clubInfo.avatar.value = club.club_avatar || ''
        this.clubInfo.telSupport.value = club.support_number || ''
        this.clubInfo.whatsAppSupport.value = club.whatsapp_number || ''
      })
    },
    openChooseFileDialog () {
      document.getElementById('browseLogo').click()
    },
    avatarValidation (avatar) {
      if (avatar.size > (800 * 1026)) {
        this.$vs.notify({
          time: 4000,
          title: this.$t('alert.error.title'),
          text: this.$t('setting.Company.validators.imageSize'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      let logoFormat = avatar.name.split('.')
      logoFormat = logoFormat[logoFormat.length - 1].toLowerCase()
      if (logoFormat !== 'png' && logoFormat !== 'jpg' && logoFormat !== 'jpeg') {
        this.$vs.notify({
          time: 4000,
          title: this.$t('alert.error.title'),
          text: this.$t('setting.Company.validators.fileFormat'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      return true
    },
    uploadAvatar (event) {
      if (event.target.files && this.avatarValidation(event.target.files[0])) uploadAvatar(event.target.files[0]).then(response => {
        this.clubInfo.logo = response.data.path
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.message.title'),
          text: this.$t('setting.Company.notifications.logoUpload.success'),
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.error.title'),
          text: error.response.data.error || error.response.data.avatar,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    removeAvatar () {
      this.clubInfo.logo = ''
      this.$vs.notify({
        time: 2400,
        title: this.$t('alert.message.title'),
        text: this.$t('setting.Company.notifications.logoDelete.success'),
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'success'
      })
    },
    getProvinces() {
      getProvinces().then(response => {
        const provinces = response.data.data

        this.provinces = provinces.map(province => {
          this.cities[province.name] = province.cities.map(city => {
            return {
              id: city.id,
              label: city.name,
              value: city.name
            }
          })

          return {
            id: province.id,
            label: province.name,
            value: province.name
          }
        })
      })
    },
    sendForm () {
      if (this.clubInfo.name.isValid &&
          this.clubInfo.telSupport.isValid &&
          this.clubInfo.whatsAppSupport.isValid) {
        const payload = {
          club_avatar: this.clubInfo.avatar.value || '',
          club_name: this.clubInfo.name.value || null,
          support_number: this.clubInfo.telSupport.value || null,
          whatsapp_number: this.clubInfo.whatsAppSupport.value || null
        }
        editClubSetting(payload).then(response => {
          this.$vs.notify({
            time: 2400,
            title: this.$t('alert.message.title'),
            text: this.$t('setting.notifications.editClub.success'),
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })

          this.$store.dispatch('removePageChanges')
          this.$router.push({name: 'Settings'})
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.error.title'),
              text: error.response.data.message || error.response.data.error,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
        this.$store.dispatch('setting/setClubInfo')
      }

    }
  },
  watch: {
    clubInfo: {
      handler (val, oldVal) {
        clearTimeout(this.firstVisitTimer)
        this.firstVisitTimer = setTimeout(() => {
          this.firstVisit = false
        }, 500)

        if (!this.firstVisit) {
          this.$store.dispatch('setPageChanges')
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
